import React from 'react'
import styled from 'styled-components'
import SolutionProcurementBannerData from '../Data/SolutionProcurementBannerData.json'
// import SolutionSmallEnterpriseTableData from '../Data/SolutionSmallEnterpriseTableData.json'
import { AppProcurementTable } from './AppProcurementTable'
import { Block } from './Block'

const AppProcurementBannerWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  margin-bottom: 90px;
  .ProcurementContain {
    max-width: 1920px;
    margin: auto;
  }
  .ProcurementSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .ProcurementHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .ProcurementHeadingBig {
    padding: 0px 0px 3% 48px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .ProcurementImage {
    height: auto;
    padding-right: 5rem;
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
      padding-right: unset;
    }
  }
  .ProcurementImageCenter {
    width: 75%;
    display: flex;
    float: right;
    @media (max-width: 1090px) {
      width: 100%;
      float: unset;
    }
  }
  .ProcurementParaSection {
    order: 0;
    margin-top: 1rem;
    padding-left: 3rem;
    @media (max-width: 1920px) and (min-width: 1820px) {
      margin-top: 4%;
    }
    @media (max-width: 1090px) {
      order: 1;
      margin-top: auto;
      padding-left: unset;
    }
  }
  .ProcurementParaPonitSection {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .ProcurementPara {
    max-width: 825px;
    padding: 15px 0px 15px 48px;
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .ProcurementSmallParaOne {
    max-width: 825px;
    padding: 15px 0px 15px 48px;
    color: #047cc2;
    font-size: 1.5rem;
    font-weight: 600;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .ProcurementParaButton {
    max-width: auto;
    padding: 15px 100px 15px 48px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .ProcurementParaButtonOne {
    max-width: auto;
    padding: 25px 100px 15px 48px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .ProcurementDownPara {
    max-width: auto;
    color: #047cc2;
    padding: 2rem 100px 15px 48px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .PonitPara {
    margin-top: 0%;
  }
  .buttonStyle {
    background: linear-gradient(90deg, #16b4ff, #644eff, #16b4ff);
    background-size: 400%;
    border-radius: 30px;
    border: none;
    padding: 0 20px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    /* margin: 3% 0% 3% 16%; */
    color: #fff;
    margin: 5% 0% 3% 0%;
    color: #fff;
    display: flex;
    margin: 2rem 0rem 5rem 17rem;
    @media (max-width: 1090px) {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
    @keyframes animate {
      0% {
        background-position: 0%;
      }
      100% {
        background-position: 400%;
      }
    }
  }
  .buttonStyle:hover {
    cursor: pointer;
    animation: animate 8s linear infinite;
    box-shadow: 0 8px 16px 0 (90deg, #16b4ff, #644eff, #16b4ff);

    .buttonStyle::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }
  .buttonStyle::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #16b4ff, #644eff, #16b4ff);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  .ProcurementDisplay {
    display: none;
  }
`

export const AppProcurementBanner = () => {
  return (
    <AppProcurementBannerWpper>
      {SolutionProcurementBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              mainContain="ProcurementContain"
              containHeading="ProcurementHeadingSmall"
              containHeadingBig="ProcurementHeadingBig"
              containParaOne="ProcurementSmallParaOne"
              containParaTwo="ProcurementPara"
              containParaFour="ProcurementDisplay"
              containParaFive="ProcurementDisplay"
              containParaThree="ProcurementDownPara"
              InternalSection="ProcurementSection"
              containImage="ProcurementImage"
              altName={data.AltName}
              paraSection="ProcurementParaSection"
              allImage="ProcurementImageCenter"
            />
          </div>
        )
      })}
      <div>
        <AppProcurementTable />
      </div>
    </AppProcurementBannerWpper>
  )
}
