export default [
  {
    id: '1',
    Heading: 'Transforming the Outflow of Your Investments',
    paraOne:
      'Fully integrated end to end platform allowing you to do business your way. Raindrop enables seamless Executive, Procurement, Finance, and Stakeholder collaboration to ensure your enterprise spend management goals and processes are directly aligned with driving consistent business value and spend across your enterprise.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/SolutionpageBanner.png',
    ALtName: 'Intelligent spend management solution',
  },
]
