import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import SolutionExecutivesBannerData from '../Data/SolutionExecutivesBannerData.json'
import { AppExecutivesTable } from './AppExecutivesTable'

const AppExecutivesBannerWapper = styled.div`
  margin-bottom: 90px;
  .ExecutivesContain {
    max-width: 1920px;
    margin: auto;
  }
  .ExecutivesSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .ExecutivesHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .ExecutivesHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .ExecutivesParaOne {
    max-width: 920px;
    color: #047cc2;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 15px 80px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .ExecutivesPara {
    max-width: 920px;
    padding: 15px 90px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .ExecutivesDownPara {
    max-width: 920px;
    color: #047cc2;
    padding: 2rem 80px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .ExecutivesImage {
    height: auto;
    padding-left: 5rem;
    @media (max-width: 1090px) {
      width: auto;
      margin: auto;
      padding-left: unset;
    }
  }
  .ExecutivesImageCenter {
    width: 75%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .ExecutivesparaSection {
    margin: 5rem 0% 0% 0%;
    @media (max-width: 1090px) {
      margin: auto;
    }
  }
  .iconColor {
    display: none;
  }
  .buttonDisplay {
    display: none;
  }
  .FreeButton {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .ExecutivesDisplay {
    display: none;
  }
`

export const AppExecutivesBanner = () => {
  return (
    <AppExecutivesBannerWapper>
      {SolutionExecutivesBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              id={data.id}
              Heading={data.Title}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              mainContain="ExecutivesContain"
              containHeading="ExecutivesHeadingSmall"
              containHeadingBig="ExecutivesHeadingBig"
              containParaOne="ExecutivesParaOne"
              containParaTwo="ExecutivesPara"
              containParaFour="ExecutivesDisplay"
              containParaFive="ExecutivesDisplay"
              containParaThree="ExecutivesDownPara"
              InternalSection="ExecutivesSection"
              containImage="ExecutivesImage"
              altName={data.AltName}
              paraSection="ExecutivesparaSection"
              IconColor="iconColor"
              ButtonDisplay="buttonDisplay"
              allImage="ExecutivesImageCenter"
            />
          </div>
        )
      })}
      <div>
        <AppExecutivesTable />
      </div>
    </AppExecutivesBannerWapper>
  )
}
